import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import './nikke-guide.scss';
import '../../generic-page.scss';
import { Alert, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { NikkeElementInline } from '../../../modules/nikke/common/components/nikke-inline-element';

const NikkeGuidesGamemodesAnomalyInterceptionPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page nikke-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Anomaly Interception</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ai.webp"
            alt="Interception"
          />
        </div>
        <div className="page-details">
          <h1>Anomaly Interception</h1>
          <h2>Guide for the Anomaly Interception mode in NIKKE.</h2>
          <p>
            Last updated: <strong>26/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Anomaly Interception" />
        <p>
          Anomaly Interception (A.I) is the latest game mode which was released
          on the 22 August update. To unlock this mode, you have to{' '}
          <strong>clear the boss of Chapter 22 (Nihilister again).</strong>
        </p>
        <SectionHeader title="Introduction" />
        <p>
          A.I is an upgraded version of Special Interception - a mode which
          provides us gears and Custom Modules. It has better rewards, and is
          much more difficult too. To access this mode, you still have to go to
          the Ark.
        </p>
        <p>
          Instead of seeing Special Interception, you will be in this new
          default interface:
        </p>
        <StaticImage
          src="../../../images/nikke/generic/ai_1.webp"
          alt="Interception"
        />
        <p>
          In this mode, you can fight 5 Bosses. You can choose whichever boss
          you want to fight (they give different rewards, mind you) by clicking
          the boss’s icon directly or clicking the left and right arrows.
        </p>
        <p>
          You can also switch back to the normal Interception interface by
          clicking the toggle at the bottom of the screen.
        </p>
        <SectionHeader title="Boss and Rewards" />
        <p>
          Before we go in the detail of each boss, you need to know some basic
          advantages/disadvantages you will have to deal with:
        </p>
        <StaticImage
          src="../../../images/nikke/generic/ai_3.webp"
          alt="Interception"
        />
        <ul>
          <li>
            <strong>All Nikkes are capped at level 400</strong>. It’s CAPPED,
            not FIXED, which means if you are lower than 400, you are at your
            current level. If your level is higher than 400, it’s 400.
          </li>
          <li>
            Boss will deal extra damage on Nikke that has the element weak to
            it. For example, Indivilla is a{' '}
            <strong className="Wind">Wind</strong> Boss, so she will deal extra
            damage on <strong className="Iron">Iron</strong> Nikkes, which are
            Crown and Liter on the picture above.
          </li>
          <li>
            There are a total 9 stages of rewards for each boss, but the reward
            from stage 7 to stage 9 are all the same{' '}
            <strong>so you only need to grind till stage 7 only</strong>. But
            before we go deeply into the reward, we did some comparisons for
            you.
          </li>
        </ul>
        <p>Here's the full list of rewards per stage:</p>
        <StaticImage
          src="../../../images/nikke/generic/ai_4.webp"
          alt="Interception"
        />
        <SectionHeader title="Which is better? SI stage 9 or AI stage 1?" />
        <StaticImage
          src="../../../images/nikke/generic/ai_5.webp"
          alt="Interception"
        />
        <p>
          <i>Credits: Akusetsu from Nikke Community Discord</i>
        </p>
        <p>As you can see, AI Stage 1, doesn’t matter which boss:</p>
        <ul>
          <li>Gives more Custom Modules.</li>
          <li>Gives the new Custom Locks.</li>
          <li>
            Gives a little less Gear XP which won’t be an issue over the long
            run.
          </li>
          <li>
            You can choose the specific drop (only Helm, only Gloves…) from A.I
            but not S.I.
          </li>
          <li>
            SI gives a slightly higher chance to drop random Manufacturer T9
            Gears than A.I stage 1 and 2.
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            Hence,{' '}
            <strong>
              always do Anomaly Interception the moment you unlock it, no matter
              if you can pass stage 1 or not
            </strong>
            .
          </p>
        </Alert>
        <p>Here's the list of the bosses and what they drop:</p>
        <div className="custom-bosses">
          <div className="single-boss">
            <h5 className="Iron">Kraken</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Iron" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Wind" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Electric" />
              </div>
            </div>
            <div className="information">
              <p>
                <strong>Drops:</strong>
              </p>
              <p>
                Custom Modules, Custom Module Shards (100%), Custom Locks,
                normal T9 gears.
              </p>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Water">Mirror Container</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Water" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Electric" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Fire" />
              </div>
            </div>
            <div className="information">
              <p>
                <strong>Drops:</strong>
              </p>
              <p>
                Custom Modules, <strong>Random GLOVES</strong> Manufacturer T9
                gears (small chance), Custom Locks, normal T9 gears,
                Manufacturer Arms (collect 200 to exchange 1 random Manufacturer
                T9 gear).
              </p>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Wind">Indivilla</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Wind" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Fire" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Iron" />
              </div>
            </div>
            <div className="information">
              <p>
                <strong>Drops:</strong>
              </p>
              <p>
                Custom Modules, <strong>Random ARMOR</strong> Manufacturer T9
                gears (small chance), Custom Locks, normal T9 gears,
                Manufacturer Arms (collect 200 to exchange 1 random Manufacturer
                T9 gear).
              </p>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Electric">Ultra</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Electric" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Iron" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Water" />
              </div>
            </div>
            <div className="information">
              <p>
                <strong>Drops:</strong>
              </p>
              <p>
                Custom Modules, <strong>Random HELMET</strong> Manufacturer T9
                gears (small chance), Custom Locks, normal T9 gears,
                Manufacturer Arms (collect 200 to exchange 1 random Manufacturer
                T9 gear).
              </p>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Fire">Harvester</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Fire" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Water" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Wind" />
              </div>
            </div>
            <div className="information">
              <p>
                <strong>Drops:</strong>
              </p>
              <p>
                Custom Modules, <strong>Random BOOTS</strong> Manufacturer T9
                gears (small chance), Custom Locks, normal T9 gears,
                Manufacturer Arms (collect 200 to exchange 1 random Manufacturer
                T9 gear).
              </p>
            </div>
          </div>
        </div>
        <h5>Custom Lock </h5>
        <p>
          Custom Locks are the new item introduced in this patch. You can spend
          a certain amount of Lock to… well, lock an OL line before you reroll
          it.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/ai_6.webp"
          alt="Interception"
        />
        <p>
          {' '}
          Custom Locks can only lock your line 1 time, while spending 2 / 3
          Custom Modules will lock that line forever until you unlock it.
        </p>
        <p>
          {' '}
          If no lines are locked, you need 20 Custom Locks to Lock one line. If
          one line is already locked, you need 30 Custom Locks to lock the
          second line.
        </p>
        <h5>T9 Manufacturer Part Equipment Box / Custom Module Shard</h5>
        <p>
          Depending on what Boss you fight, you can collect either Custom Module
          Shards (Kraken), or T9 Manufacturer Box (the part that drops depends
          on the Boss you are fighting).
        </p>
        <StaticImage
          src="../../../images/nikke/generic/ai_7.webp"
          alt="Interception"
        />
        <p>Custom Module Shards:</p>
        <ul>
          <li>
            100% chance of shards dropping. You need to collect 100 shards for a
            guaranteed 1 Custom Module.
          </li>
          <li>
            If you can clear stage 6 Kraken, you guarantee one rock drop every
            day.
          </li>
        </ul>
        <p>T9 Manufacturer Box</p>
        <ul>
          <li>
            Unlike shards, no guarantee of this T9M Box dropping. The higher
            stage you clear, the higher the chance of this box dropping.
          </li>
          <li>
            The part that drops depends on the Boss you fight (you can find
            which boss drops which part in the info given above).
          </li>
        </ul>
        <h5>Custom Module Random Bonus Drops</h5>
        <p>
          This is the bonus reward you can get once you finish a run against any
          A.I boss. The higher stage you clear, the more the chance of this
          Bonus Drop. As a bonus, the higher the stage you clear, the higher the
          chance of more than 1 rock dropping (capped at stage 7). At stage 6
          and above, you can even get 3 rocks, otherwise you can only get a
          maximum of 2 rocks per run (1% chance)!
        </p>
        <StaticImage
          src="../../../images/nikke/generic/ai_8.webp"
          alt="Interception"
        />
        <p>
          The maximum possible rocks per run are 3, for a total maximum of 10 a
          day (including 3 runs and the custom module shard box from Kraken).
          The total maximum possible in a day is 20 custom modules during double
          drop weekends!
        </p>
        <p>
          In case you are wondering what’s Overload, who you should spend those
          blue rocks on, don’t worry we got you:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="OL Gear - Intro"
            link="/nikke/guides/overload-gear-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ol.webp"
                alt="Overload Gear - Intro"
              />
            }
          />
          <CategoryCard
            title="OL Gear - Recommendations"
            link="/nikke/guides/overload-gear-recommendations"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_olrec.jpg"
                alt="OL Gear - Recommendations "
              />
            }
          />
          <CategoryCard
            title="OL Gear - Rerolling"
            link="/nikke/guides/overload-gear-reroll"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_olreroll.jpg"
                alt="OL Gear - Rerolling "
              />
            }
          />
        </Row>
        <p>
          Whatever you lack, just fight the corresponding boss to hopefully
          receive the item you lack.
        </p>
        <SectionHeader title="Bosses and teams" />
        <p>
          Each boss has a different strategy, and more in-depth guides will be
          released soon in the near future. In general, Liter and D:KW are the
          best Burst I units to use, except against Harvester where Dorothy is
          the BiS B1 due to her elemental advantage. The best support unit is
          Crown, who can be paired alongside Naga (except for against Kraken),
          who easily kills Naga due to Naga’s element being weak to Iron. If you
          lack Crown, just use the best support structure you may have
          available: Tia-Naga, Blanc-Noir, S.Rosanna+Helm, etc..
        </p>
        <p>
          For now, to fill the B3 spots of your team, you can pick and choose
          DPS from this table against each boss:
        </p>
        <div className="custom-bosses">
          <div className="single-boss">
            <h5 className="Iron">Kraken</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Iron" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Wind" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Electric" />
              </div>
            </div>
            <div className="information">
              <p className="with-margin">
                <strong>Best DPS:</strong>
              </p>
              <ol className="list-with-chars-inline">
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="scarlet-black-shadow"
                    enablePopover
                  />{' '}
                  & <NikkeCharacter mode="inline" slug="alice" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="scarlet-black-shadow"
                    enablePopover
                  />{' '}
                  &{' '}
                  <NikkeCharacter mode="inline" slug="red-hood" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="sakura-bloom-in-summer"
                    enablePopover
                  />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="noir" enablePopover />
                </li>
              </ol>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Water">Mirror Container</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Water" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Electric" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Fire" />
              </div>
            </div>
            <div className="information">
              <p className="with-margin">
                <strong>Best DPS:</strong>
              </p>
              <ol className="list-with-chars-inline">
                <li>
                  <NikkeCharacter mode="inline" slug="ein" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="sparkling-summer-anis"
                    enablePopover
                  />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="naga" enablePopover />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="scarlet" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="guillotine"
                    enablePopover
                  />
                </li>
              </ol>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Wind">Indivilla</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Wind" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Fire" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Iron" />
              </div>
            </div>
            <div className="information">
              <p className="with-margin">
                <strong>Best DPS:</strong>
              </p>
              <ol className="list-with-chars-inline">
                <li>
                  <NikkeCharacter mode="inline" slug="alice" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="asuka-shikinami-langley"
                    enablePopover
                  />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="modernia" enablePopover />
                </li>
              </ol>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Electric">Ultra</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Electric" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Iron" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Water" />
              </div>
            </div>
            <div className="information">
              <p className="with-margin">
                <strong>Best DPS:</strong>
              </p>
              <ol className="list-with-chars-inline">
                <li>
                  <NikkeCharacter mode="inline" slug="red-hood" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="snow-white"
                    enablePopover
                  />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="maxwell" enablePopover />
                </li>

                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="laplace"
                    enablePopover
                    showTreasure
                  />
                </li>
              </ol>
            </div>
          </div>
          <div className="single-boss">
            <h5 className="Fire">Harvester</h5>
            <div className="boss-info">
              <div>
                <p>
                  <strong>Element:</strong>
                </p>
                <NikkeElementInline element="Fire" />
              </div>
              <div>
                <p>
                  <strong>Weak to:</strong>
                </p>
                <NikkeElementInline element="Water" />
              </div>
              <div>
                <p>
                  <strong>Strong against:</strong>
                </p>
                <NikkeElementInline element="Wind" />
              </div>
            </div>
            <div className="information">
              <p className="with-margin">
                <strong>Best DPS:</strong>
              </p>
              <ol className="list-with-chars-inline">
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="ludmilla-winter-owner"
                    enablePopover
                  />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="dorothy" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="scarlet-black-shadow"
                    enablePopover
                  />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="alice" enablePopover />
                </li>
                <li>
                  <NikkeCharacter mode="inline" slug="red-hood" enablePopover />
                </li>
                <li>
                  <NikkeCharacter
                    mode="inline"
                    slug="viper"
                    enablePopover
                    showTreasure
                  />
                </li>
              </ol>
            </div>
          </div>
        </div>
        <StaticImage
          src="../../../images/nikke/generic/ai_9.webp"
          alt="Interception"
        />
        <p>
          <i>Doro after retrying 127627955 times on A.I</i>
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesAnomalyInterceptionPage;

export const Head: React.FC = () => (
  <Seo
    title="Anomaly Interception | NIKKE | Prydwen Institute"
    description="Guide for the Anomaly Interception mode in Goddess of Victory: NIKKE."
  />
);
