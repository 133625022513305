import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import './nikke-element.scss';

interface IProps {
  element: string;
}

export const NikkeElementInline: React.FC<IProps> = ({ element }) => {
  return (
    <div className={`nikke-element-inline ${element}`}>
      {element === 'Electric' && (
        <>
          <StaticImage
            src="../../../../images/nikke/icons/element_electric.png"
            alt={element}
          />
          <strong>{element}</strong>
        </>
      )}
      {element === 'Fire' && (
        <>
          <StaticImage
            src="../../../../images/nikke/icons/element_fire.png"
            alt={element}
          />
          <strong>{element}</strong>
        </>
      )}
      {element === 'Iron' && (
        <>
          <StaticImage
            src="../../../../images/nikke/icons/element_iron.png"
            alt={element}
          />
          <strong>{element}</strong>
        </>
      )}
      {element === 'Water' && (
        <>
          <StaticImage
            src="../../../../images/nikke/icons/element_water.png"
            alt={element}
          />
          <strong>{element}</strong>
        </>
      )}
      {element === 'Wind' && (
        <>
          <StaticImage
            src="../../../../images/nikke/icons/element_wind.png"
            alt={element}
          />
          <strong>{element}</strong>
        </>
      )}
      {element === 'Unknown' && (
        <>
          <strong>{element}</strong>
        </>
      )}
    </div>
  );
};
